import { useState } from 'react';
import patchApproveUser from '../services/patchApproveUser';
import patchCreateUser from '../services/patchCreateUser';

interface AlertMessage {
  description: string;
  variant: 'error' | 'success';
}

interface Status {
  loading: boolean;
  alertMessage: AlertMessage;
  success: boolean;
  error: boolean;
}
export type UserManagementActions = 'APPROVE' | 'REJECT' | 'CREATE';
export interface SelectedUserManagement {
  email: string;
  id: string;
  companies: string[];
  action: UserManagementActions;
}
type MessageType = {
  [key: string]: AlertMessage;
};

/**
 * Approve, Reject and Create Auth0 user state hook
 * @param handleRefresh Function to refresh
 * @remarks
 * this hook handles the screen status relationated to the service endpoint and response
 */
const useApproveUser = (handleRefresh: () => void) => {
  const MESSAGES: MessageType = {
    ERROR_GENERIC: {
      description: 'COMMON:ERRORS:IT_ERROR',
      variant: 'error',
    },
    SUCCESS_APPROVED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:APPROVED_USER',
      variant: 'success',
    },
    SUCCESS_REJECTED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:REJECTED_USER',
      variant: 'success',
    },
    ERROR_APPROVED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:APPROVED_USER_ERROR',
      variant: 'error',
    },
    ERROR_REJECTED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:REJECTED_USER_ERROR',
      variant: 'error',
    },
    SUCCESS_CREATED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:CREATED_USER',
      variant: 'success',
    },
    ERROR_CREATED: {
      description: 'USERS_MANAGEMENT:ALERT_MESSAGES:CREATED_USER_ERROR',
      variant: 'error',
    },
  } as const;

  const [status, setStatus] = useState<Status>({
    loading: false,
    success: false,
    error: false,
    alertMessage: MESSAGES.ERROR_GENERIC,
  });

  const [selectedUser, setSelectedUser] = useState<SelectedUserManagement>({
    email: '',
    id: '',
    companies: [],
    action: 'APPROVE',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsError = (error: boolean) => {
    setStatus((prevState) => ({ ...prevState, error }));
  };

  /**
   * Function to set the sucess state.
   * @param {boolean} success - The success state.
   */
  const setIsSuccess = (success: boolean) => {
    setStatus((prevState) => ({ ...prevState, success }));
  };

  /**
   * Function to set the sucess/error state with message.
   * @param success - True if success.
   * @param message - The message.
   */
  const setResponseMessage = (success: boolean, message: AlertMessage) => {
    setStatus({
      loading: false,
      error: !success,
      success: success,
      alertMessage: message,
    });
  };

  /**
   * Approve or reject Auth0 user creation service handler
   * @async
   * @param body - body payload request.
   * @param body.approve - true to approve the user.
   * @param body.userId - user id to approve or reject.
   */
  async function approveUser(body: { approve: boolean; userId: string }) {
    try {
      setStatus((prevState) => ({ ...prevState, loading: true }));
      await patchApproveUser(body);
      setTimeout(() => {
        handleRefresh();
        setResponseMessage(
          true,
          body.approve ? MESSAGES.SUCCESS_APPROVED : MESSAGES.SUCCESS_REJECTED
        );
      }, 1000);
    } catch (error) {
      setResponseMessage(false, body.approve ? MESSAGES.ERROR_APPROVED : MESSAGES.ERROR_REJECTED);
    } finally {
      setIsModalOpen(false);
    }
  }

  /**
   * Create Auth0 user service handler
   * @async
   * @param body - body payload request.
   * @param body.userId - user id to approve or reject.
   */
  async function createUser(body: { userId: string }) {
    try {
      setStatus((prevState) => ({ ...prevState, loading: true }));
      await patchCreateUser(body);
      setTimeout(() => {
        handleRefresh();
        setResponseMessage(true, MESSAGES.SUCCESS_CREATED);
      }, 1000);
    } catch (error) {
      setResponseMessage(false, MESSAGES.ERROR_CREATED);
    } finally {
      setIsModalOpen(false);
    }
  }
  /**
   * Handle submit
   */
  const handleSubmit = () => {
    if (selectedUser.action != 'CREATE') {
      approveUser({ approve: selectedUser.action === 'APPROVE', userId: selectedUser.id });
      return;
    }
    createUser({ userId: selectedUser.id });
  };
  /**
   * On click action buttons
   * @param email Selected user email
   * @param id Selected user id
   * @param companies Selected user companies
   * @param action Actions clicked
   */
  const onActionClick = (u: SelectedUserManagement) => {
    setIsModalOpen(true);
    setSelectedUser(u);
  };

  const { loading: isLoading, success: isSuccess, error: isError, alertMessage } = status;

  return {
    isLoading,
    isSuccess,
    isError,
    alertMessage,
    setIsSuccess,
    setIsError,
    handleSubmit,
    isModalOpen,
    setIsModalOpen,
    selectedUser,
    setSelectedUser,
    onActionClick,
  };
};

export default useApproveUser;
