import { gray } from 'theme/colors';

const styles = {
  spinnerBox: { display: 'flex', width: '100%', justifyContent: 'center', py: '10rem' },
  buttonBox: { display: 'flex', justifyContent: 'flex-end' },
  button: { alignSelf: 'flex-end' },
  customizeContainer: (responsive: boolean) => ({
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    width: '100%',
    gap: '0.5rem',
  }),
  colorInput: {
    minWidth: '18%',
    marginRight: 1,
  },
  colorBox: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: 1,
    borderColor: gray[50],
  },
};

export default styles;
