import { Box, Tooltip } from '@mui/material';
import i18n from '@i18n';
import { Yup } from '@validations';
import styles from './styles';

export const COLUMNS = [
  {
    title: 'DISPERSIONS:FAST_DISPERSION:NAME',
    content: ({ firstName }) => {
      const isMaxCharacter = firstName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && firstName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{firstName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'DISPERSIONS:FAST_DISPERSION:LAST_NAME',
    content: ({ lastName }) => {
      const isMaxCharacter = lastName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && lastName} arrow>
          <Box sx={[styles.rowEllipsis, styles.lastNameMaxWidth]}>{lastName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'DISPERSIONS:FAST_DISPERSION:CUIL',
    content: ({ cuil }) => {
      const isMaxCharacter = cuil.length > 25;
      return (
        <Tooltip title={isMaxCharacter && cuil} arrow>
          <Box sx={[styles.rowEllipsis, styles.cuilMaxWidth]}>{cuil}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'DISPERSIONS:FAST_DISPERSION:PRODUCT',
    content: ({ product }) => {
      const isMaxCharacter = product.length > 25;
      return (
        <Tooltip title={isMaxCharacter && product} arrow>
          <Box sx={[styles.rowEllipsis, styles.productMaxWidth]}>{product}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'DISPERSIONS:FAST_DISPERSION:CATEGORY',
    content: ({ categories }) => {
      const values = categories.split(',');
      const translatedValues = values.map((value) => i18n.t(`CATEGORIES:${i18n.t(value)}`));
      const isMaxCharacter = translatedValues.join(', ').length > 20;
      return (
        <Tooltip title={isMaxCharacter && translatedValues.join(', ')} arrow>
          <Box sx={[styles.rowEllipsis, styles.productMaxWidth]}>{translatedValues.join(', ')}</Box>
        </Tooltip>
      );
    },
  },
];

/** Parse product options */
export const PARSE_PRODUCT_OPTIONS = (value) => ({ label: value, value });

/** Parse catgories options*/
export const PARSE_CATEGORY_OPTIONS = (value) => ({
  label: i18n.t(`CATEGORIES:${value}`),
  value,
});

export const CONFIG_FIELDS = [
  {
    name: 'firstName',
    label: 'COMMON:NAME',
    validation: Yup.string().nameAndLastName('COMMON:VALIDATIONS:INVALID_NAME'),
    defaultValue: '',
  },
  {
    name: 'lastName',
    label: 'COMMON:LAST_NAME',
    validation: Yup.string().nameAndLastName('COMMON:VALIDATIONS:INVALID_LASTNAME'),
    defaultValue: '',
  },
  {
    name: 'cuil',
    label: 'COMMON:CUIL',
    validation: Yup.string()
      .matches(/^(?!0*$)\d+$/, 'COMMON:VALIDATIONS:INVALID_CUIL')
      .min(9, 'COMMON:VALIDATIONS:INVALID_CUIL')
      .max(12, 'COMMON:VALIDATIONS:INVALID_CUIL'),
    defaultValue: '',
  },
];

export const VALIDATION_SCHEMA = Yup.object().shape(
  CONFIG_FIELDS.reduce((accumulator, { name, validation }) => {
    accumulator[name] = validation;
    return accumulator;
  }, {})
);

export const CONFIG_AMOUNT_FIELD = [
  {
    name: 'amount',
    label: 'DISPERSIONS:FAST_DISPERSION:AMOUNT',
    defaultValue: '',
    type: 'number',
  },
];

export const VALIDATION_AMOUNT_SCHEMA = Yup.object().shape({
  amount: Yup.string()
    .required('COMMON:VALIDATIONS:REQUIRED_FIELD')
    .transform((value) => value.replace(',', '.'))
    .matches(/^\d+(\.\d+)?$/, 'COMMON:VALIDATIONS:INVALID_AMOUNT')
    .min(1, 'COMMON:VALIDATIONS:INVALID_AMOUNT')
    .test('is-number', 'COMMON:VALIDATIONS:INVALID_AMOUNT', (value) => !isNaN(parseFloat(value)))
    .test('checkDecimals', 'COMMON:VALIDATIONS:INVALID_AMOUNT_DECIMALS', (num) => {
      const decimals = String(num).split('.')[1];
      if (!decimals) {
        return true;
      } else {
        return decimals.length < 3;
      }
    }),
});
