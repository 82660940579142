import useSWR from 'swr';

/**
 * Obtains a list of products by customer
 * @param allowRequest - Indicates whether the request to fetch products should be made. If `false`, the request will not be executed
 * @param customerId - customerId to get assignable products
 */
const useAvailableProductsByCustomer = ({
  customerId,
  allowRequest = true,
}: {
  customerId?: number;
  allowRequest?: boolean;
}) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && customerId ? `/v2/partner/products/${customerId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data: data?.products ?? [], isLoading, error };
};
export default useAvailableProductsByCustomer;
