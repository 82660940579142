import { useState } from 'react';
import { apiClient } from 'config/api';

/**
 * Assign products for a specified Customer/Partner
 */
const useAssignPartnerProducts = () => {
  const ERROR_MESSAGE_GENERIC = {
    title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
    description: 'COMMON:ERRORS:DEFAULT_ERROR',
    details: [],
    variant: 'error',
  };

  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
    failed: false,
    alertMessage: ERROR_MESSAGE_GENERIC,
  });

  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsError = (error) => {
    setStatus((prevState) => ({ ...prevState, error }));
  };
  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsFailed = (failed) => {
    setStatus((prevState) => ({ ...prevState, failed }));
  };

  /**
   * Function to set the sucess state.
   * @param {boolean} success - The success state.
   */
  const setIsSuccess = (success) => {
    setStatus((prevState) => ({ ...prevState, success }));
  };
  /**
   * Generates an alert message based on the success and failure of product additions.
   *
   * @param {Object} data - The data containing information about product additions.
   * @param {Array<string>} data.success - The list of successfully added products.
   * @param {Array<string>} data.failed - The list of products that failed to be added.
   * @returns {Object} MESSAGE - The generated alert message.
   */
  const getAlertMessage = (data) => {
    const { success, failed } = data;
    let MESSAGE = {
      title: 'CLIENTS:ASSIGN_PRODUCT_MODAL:SUCCESS_TITLE',
      description: 'CLIENTS:ASSIGN_PRODUCT_MODAL:COMPLETE_DESCRIPTION',
      details: success,
      variant: 'success',
    };
    if (data.failed.length) {
      MESSAGE.details = failed;
      if (data.success.length) {
        const result = success.join(', ');
        //TODO: move this text to translations
        MESSAGE.description = `¡Listo! Has enviado con éxito la información de los productos: ${result}. Pero los siguientes productos no pudieron ser agregados: `;
      } else {
        MESSAGE.title = 'COMMON:ERRORS:GENERIC_ERROR_TITLE';
        MESSAGE.description = 'CLIENTS:ASSIGN_PRODUCT_MODAL:FAILED_DESCRIPTION';
        MESSAGE.variant = 'error';
      }
    }
    return MESSAGE;
  };

  /**
   * Assigns products to a specific company.
   *
   * @async
   * @function assignProduct
   * @param {number} customerId - The ID of the customer to which the products will be assigned.
   * @param {Object} products - The products to be assigned to the company.
   * @returns {Promise<void>} - A promise that resolves when the assignment is complete.
   * @throws {Error} - Throws an error if the request fails.
   */
  async function assignProduct(customerId, products) {
    try {
      setStatus((prevState) => ({ ...prevState, loading: true }));
      const { data } = await apiClient.post(
        `/v2/partner/customer/assign-product/${customerId}`,
        products
      );
      if (data.success.length === 0 && data.failed.length > 0) {
        setStatus((prevState) => ({
          ...prevState,
          loading: false,
          failed: true,
          alertMessage: getAlertMessage(data),
          data,
        }));
      } else {
        setStatus((prevState) => ({
          ...prevState,
          success: true,
          loading: false,
          alertMessage: getAlertMessage(data),
          data,
        }));
      }
    } catch (error) {
      if (error || (error.response && error.response.status === 500)) {
        setStatus((prevState) => ({ ...prevState, success: false, error: true, loading: false }));
      }
    }
  }

  const {
    loading: isLoading,
    success: isSuccess,
    error: isError,
    failed: isFailed,
    alertMessage,
  } = status;

  return {
    isLoading,
    isSuccess,
    isFailed,
    isError,
    alertMessage,
    setIsSuccess,
    setIsFailed,
    setIsError,
    assignProduct,
  };
};

export default useAssignPartnerProducts;
