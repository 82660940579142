export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
} as const;

export type NeutralColor = typeof neutral;

export const gray = {
  50: '#808080',
  60: '#666666',
  70: '#4D4D4D',
  80: '#333333',
  90: '#1A1A1A',
  100: '#000000',
} as const;

export type GrayColor = typeof gray;

// Egyptian blue
export const blue = {
  600: '#a599d7',
  500: '#8e81cd',
  400: '#7769c3',
  300: '#5e53b8',
  200: '#413dae',
  lightest: '#C9DDFF',
  light: '#93BBFF',
  main: '#1928A3',
  dark: '#123572',
  contrastText: '#FFFFFF',
};

// Carmine
export const scarlet = {
  600: '#a599d7',
  500: '#8e81cd',
  400: '#7769c3',
  300: '#5e53b8',
  200: '#413dae',
  lightest: '#C9DDFF',
  light: '#93BBFF',
  main: '#1928A3',
  dark: '#123572',
  contrastText: '#FFFFFF',
} as const;

export const success = {
  600: '#ade3a1',
  500: '#97db8a',
  400: '#80d472',
  300: '#67cc5a',
  200: '#49c440',
  light: '#ade3a1',
  main: '#19BC20',
  dark: '#0d6211',
  contrastText: '#FFFFFF',
} as const;

export const info = {
  600: '#d1e0ff',
  500: '#c6d9ff',
  400: '#bad1ff',
  300: '#adcaff ',
  200: '#a0c2ff',
  light: '#d1e0ff',
  main: '#93BBFF',
  dark: '#002460',
  contrastText: '#FFFFFF',
} as const;

export const warning = {
  600: '#ffe8a2',
  500: '#fee28b',
  400: '#fcdc72',
  300: '#fad758',
  200: '#f6d13a',
  light: '#ffe8a2',
  main: '#F3CC00',
  dark: '#8d7600',
  contrastText: '#FFFFFF',
} as const;

export const error = {
  600: '#fea691',
  500: '#fa9077',
  400: '#f5785d',
  300: '#ee5f45',
  200: '#e7422c',
  light: '#fea691',
  main: '#DE1313',
  dark: '#800b0b',
  contrastText: '#FFFFFF',
} as const;

export const benefit = {
  main: '#76EEC6',
  light: '#ebfcf7',
  dark: '#118760',
  contrastText: '#1928A3',
} as const;

export const business = {
  main: '#0F141A',
  light: '#8a8c90',
  dark: '#000000',
  contrastText: '#FFFFFF',
} as const;

export const lunch = {
  main: '#F2A551',
  light: '#ffd7b1',
  dark: '#ce730f',
  contrastText: '#1928A3',
} as const;

export const background = {
  background: '#F4F4F4',
  blueLight: '#EEEFFF',
};

export const flota = {
  main: '#592289',
  dark: '#240e37',
  light: '#b699ca',
  contrastText: '#C9DDFF',
};

export const defaultProductColor = {
  main: gray[50],
  dark: gray[90],
  contrastText: gray[100],
};
