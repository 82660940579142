import { apiClient } from 'config/api';

/**
 * Service for approve or reject an user creation in Auth0
 * @async
 * @param body - body payload request.
 * @param body.approve - true to approve the user.
 * @param body.userId - user id to approve or reject.
 */
async function patchApproveUser({ approve, userId }: { approve: boolean; userId: string }) {
  const url = `/auth0/user/${userId}/approve`;

  const response = await apiClient.patch(url, { approve });
  return response.data;
}
export default patchApproveUser;
