import { benefit, business, lunch, blue } from 'theme/colors';

export const SIDE_NAV_WIDTH = '16.5rem';
export const TOP_NAV_HEIGHT = '4.5rem';

export type ProductName = 'Beneficio' | 'Corporativo' | 'Almuerzo' | 'Gobierno';

export type ProductsAvailablesColors = {
  name: ProductName;
  colors?: {
    main: string;
  };
};

export interface ProductColor {
  main: string;
  light?: string;
  dark?: string;
  contrastText: string;
}

export const PRODUCTS_COLORS: Record<ProductName, ProductColor> = {
  Beneficio: benefit,
  Corporativo: business,
  Almuerzo: lunch,
  Gobierno: blue,
};
