import { Box, Tooltip } from '@mui/material';
import { INFRA, OPERATIONS_SUPERVISOR } from '@globalConstants';
import { t } from '@i18n';
import { SelectedUserManagement } from './data/useApproveUser/hooks/useApproveUser';
import { User } from './data/useGetAllUsersPaginated';
import ActionButtons from './modules/ActionButtons';
import styles from './styles';

export const DEFAULT_ROWS_PER_PAGE = 25;

/**
 * User list columns for the table
 */
export const userListColumns = [
  {
    title: 'COMMON:FIRST_NAME',
    content: ({ user }: { user: User }) => {
      const isMaxCharacter = user.firstname && user.firstname.length > 25;
      return (
        <Tooltip title={isMaxCharacter && user.firstname} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{user.firstname}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:LAST_NAME',
    content: ({ user }: { user: User }) => {
      const isMaxCharacter = user.lastname && user.lastname.length > 25;
      return (
        <Tooltip title={isMaxCharacter && user.lastname} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{user.lastname}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:EMAIL',
    content: ({ user }: { user: User }) => {
      const isMaxCharacter = user.email.length > 20;
      return (
        <Tooltip title={isMaxCharacter && user.email} arrow>
          <Box sx={[styles.rowEllipsis, styles.emailMaxWidth]}>{user.email}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:COMPANY',
    content: ({ user }: { user: User }) => {
      return (
        <>
          {user.companies.map((company) => {
            const isMaxCharacter = company && company.length > 20;
            return (
              <Box key={company}>
                <Tooltip
                  title={isMaxCharacter && company}
                  slotProps={{
                    popper: { modifiers: [{ name: 'offset', options: { offset: [0, -8] } }] },
                  }}
                  arrow
                >
                  <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{company}</Box>
                </Tooltip>
              </Box>
            );
          })}
        </>
      );
    },
  },
  {
    title: 'COMMON:STATUS',
    content: ({ user }: { user: User }) =>
      user.status && t(`USERS_MANAGEMENT:USER_STATUS:${user.status}`),
  },
  {
    title: 'USERS_MANAGEMENT:FIRST_TIME_LOGIN',
    content: ({ user }: { user: User }) => {
      return t(user.firstTimeLogin ? 'COMMON:YES' : 'COMMON:NO');
    },
  },
  {
    title: 'COMMON:ACTIONS',
    content: ({
      user,
      callback,
    }: {
      user: User;
      callback: (u: SelectedUserManagement) => void;
    }) => <ActionButtons {...{ user, callback }} />,
    role: [OPERATIONS_SUPERVISOR, INFRA],
  },
];
