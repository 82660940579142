import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { parseAmount } from '@utils';
import styles from './AreaItem.styles';
import { Area } from './AreasList';

/**
 * Component that renders a single customer area with its corresponding balance.
 *
 * @param area - The customer area.
 * @returns The rendered component.
 */
const AreaItem: FC<{ area: Area }> = ({ area }) => (
  <Box sx={styles.container}>
    <Typography sx={styles.nameText}>{area.fantasyName}</Typography>
    <Typography sx={styles.balanceText}>{parseAmount(area.balance)}</Typography>
  </Box>
);

export default AreaItem;
