import { t } from 'i18next';
import { blue } from 'theme/colors';
import { Box, SvgIcon, Tooltip } from '@mui/material';
import { IconCircleCheck, IconCirclePlus, IconXboxX } from '@tabler/icons-react';
import { Button } from '@atoms';
import { OPERATIONS_SUPERVISOR, INFRA } from '@globalConstants';
import { useRoles } from '@hooks';
import { User } from '@modules/usersManagment/data/useGetAllUsersPaginated';
import { SelectedUserManagement } from '../../data/useApproveUser/hooks/useApproveUser';
import styles from '../../styles';

/**
 * Buttons on action column on users table
 * @param callback function to trigger on button click
 */
export default function ActionButtons({
  user,
  callback,
}: {
  user: User;
  callback: (u: SelectedUserManagement) => void;
}) {
  const { validateRoles } = useRoles();
  const showApproveRejectActions =
    user.status === 'PENDING' && validateRoles([OPERATIONS_SUPERVISOR]);
  const showCreateAction = user.status === 'APPROVED' && validateRoles([INFRA]);
  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <ActionButton
        {...{
          tooltip: 'COMMON:APPROVE',
          icon: <IconCircleCheck color={blue.main} />,
          callback: () => callback({ ...user, action: 'APPROVE' }),
          show: showApproveRejectActions,
        }}
      />
      <ActionButton
        {...{
          tooltip: 'COMMON:REJECT',
          icon: <IconXboxX color={blue.main} />,
          callback: () => callback({ ...user, action: 'REJECT' }),
          show: showApproveRejectActions,
        }}
      />
      <ActionButton
        {...{
          tooltip: 'USERS_MANAGEMENT:ACTIONS:CREATE',
          icon: <IconCirclePlus color={blue.main} />,
          callback: () => callback({ ...user, action: 'CREATE' }),
          show: showCreateAction,
        }}
      />
    </Box>
  );
}

interface ActionButtonProps {
  tooltip: string;
  icon: React.ReactNode;
  callback: () => void;
  show: boolean;
}

/**
 *  Button icon component of action buttons column
 * @param tooltip text to show on tooltip
 * @param icon icon to render inside button
 * @param callback function to trigger on button click
 */
const ActionButton = ({ tooltip, icon, callback, show }: ActionButtonProps) => {
  if (!show) return <></>;
  return (
    <Tooltip title={t(tooltip)} arrow>
      <Box>
        <Button
          customStyle={{
            button: styles.actionButton,
            box: styles.actionButtonBox,
          }}
          onClick={callback}
          content={<SvgIcon fontSize='medium'>{icon}</SvgIcon>}
        />
      </Box>
    </Tooltip>
  );
};
