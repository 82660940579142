import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

interface UseDownloadConsumptionByCategoryResponse {
  isLoading: boolean;
  download: (companyId: string, params: Record<string, string>) => Promise<void>;
  error: string | undefined;
}

/**
 * Custom hook to download consumptions by category
 * @returns { isLoading, download, error }
 */
const useDownloadConsumptionByCategory = (): UseDownloadConsumptionByCategoryResponse => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();

  const download = useCallback(
    async (companyId: string, params: Record<string, string>) => {
      setIsloading(true);
      setError(undefined);
      try {
        const query = new URLSearchParams({ ...params, companyId }).toString();
        const { headers, data } = await apiClient.get(
          `export/client/consumption-by-category?${query}`,
          {
            responseType: 'blob',
          }
        );

        /**
         * Get the value of a header from the headers object
         * @param key - The key to look for in the headers object
         */
        const getHeaderValue = (key: string) => {
          const originalKey = Object.keys(headers).find(
            (k) => k.toLowerCase() === key.toLowerCase()
          );
          return originalKey ? headers[originalKey] : undefined;
        };

        const mimeTypes: { [key: string]: string } = {
          'image/bmp': '.bmp',
          'image/gif': '.gif',
          'image/jpeg': '.jpg',
          'image/png': '.png',
          'image/svg+xml': '.svg',
          'image/tiff': '.tiff',
          'application/pdf': '.pdf',
          'text/html': '.html',
          'text/plain': '.txt',
          'application/msword': '.doc',
          'application/zip': '.zip',
          'application/vnd.ms-excel': '.xls',
          'application/vnd.rar': '.rar',
          'text/csv': '.csv',
        };

        const contentDisposition = getHeaderValue('content-disposition');
        const contentType = getHeaderValue('content-type') ?? 'application/vnd.ms-excel';
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : t('CONSUMPTION:BY_CATEGORY', {
              extension: mimeTypes[contentType],
            });

        const blob = new Blob([data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    [t]
  );

  return { isLoading, download, error };
};

export default useDownloadConsumptionByCategory;
