import { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { IconFileExport, IconSearch } from '@tabler/icons-react';
import { Button, DateTimePicker, IconButton } from '@atoms';
import { useSelectedCompanyProducts } from '@hooks';
import { SelectFetch } from '@molecules';
import { useCompanyStore } from '@stores';
import { getDatesFromTheLastMonth } from '@utils';
import { zodResolver } from '@hookform/resolvers/zod';
import searchBoxConsumptionByCommerceSchema from './SearchBoxConsumptionByCommerce.schema';
import { DATE_PICKER_PROPS } from '../constants';
import useDownloadConsumptionByCommerce from '../data/useDownloadConsumptionByCommerce';
import styles from '../styles';

interface SearchBoxConsumptionByCommerceProps {
  filtersOpen?: boolean;
  setFiltersOpen?: (open: boolean) => void;
  isLoading?: boolean;
  params: Record<string, string>;
  onSubmit: (values: Record<string, string | Date>) => void;
  dataLength?: number;
}

/**
 * Displays filters
 * @param {SearchBoxConsumptionByCommerceProps} props
 * @param {object} props.params
 */
const SearchBoxConsumptionByCommerce: React.FC<SearchBoxConsumptionByCommerceProps> = ({
  isLoading = false,
  params,
  onSubmit,
  dataLength,
}: SearchBoxConsumptionByCommerceProps) => {
  const { t } = useTranslation();
  const responsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { data: products, isLoading: isLoadingProducts } = useSelectedCompanyProducts();
  const { selectedCompany } = useCompanyStore();
  const previousCompanyRef = useRef<number | null>(null);
  const { fromDate, toDate } = getDatesFromTheLastMonth();
  const { download, isLoading: isLoadingConsumption } = useDownloadConsumptionByCommerce();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      fromDate,
      toDate,
      product: params?.product || '',
    },
    resolver: zodResolver(searchBoxConsumptionByCommerceSchema),
    mode: 'onChange',
  });

  const disableButton = isLoading || isSubmitting;

  useEffect(() => {
    if (!isLoadingProducts) {
      const product = products.length === 1 ? products[0] : '';
      const values = { fromDate, toDate, product };
      if (
        previousCompanyRef.current !== selectedCompany ||
        (products.length === 1 && !params.product)
      ) {
        reset(values);
        onSubmit({ product });
      }
      previousCompanyRef.current = selectedCompany;
    }
  }, [
    fromDate,
    isLoadingProducts,
    onSubmit,
    params.product,
    products,
    reset,
    selectedCompany,
    toDate,
  ]);

  return (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.header}>
        <Typography variant='h4'>{t('CONSUMPTION:FILTERS_TITLE')}</Typography>
      </Box>
      <Box sx={styles.content(responsive)}>
        <Box sx={styles.firstLine(responsive)}>
          <Box sx={styles.inputsContainer(responsive)}>
            <Box sx={styles.boxContainer}>
              <Controller
                name='fromDate'
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    handleChange={(date: Date) => field.onChange(date)}
                    error={Boolean(errors.fromDate)}
                    inputLabel='COMMON:FROM_DATE'
                    helperText={errors.fromDate ? String(errors.fromDate?.message) : undefined}
                    {...DATE_PICKER_PROPS}
                  />
                )}
              />
            </Box>
            <Box sx={styles.boxContainer}>
              <Controller
                name='toDate'
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    handleChange={(date: Date) => field.onChange(date)}
                    error={Boolean(errors.toDate)}
                    inputLabel='COMMON:TO_DATE'
                    helperText={errors.toDate ? String(errors.toDate?.message) : undefined}
                    {...DATE_PICKER_PROPS}
                  />
                )}
              />
            </Box>
            <Box sx={styles.boxContainer}>
              {isLoadingProducts ? (
                <Skeleton height={50} sx={styles.skeleton} />
              ) : (
                <Controller
                  name='product'
                  control={control}
                  render={({ field }) => (
                    <SelectFetch
                      {...field}
                      label='COMMON:PRODUCT'
                      error={Boolean(errors.product)}
                      getOptions={useSelectedCompanyProducts}
                      parseOption={(value: string) => ({ text: value, value })}
                      customStyles={styles.selectFetch}
                      helperText={errors.product ? String(errors.product?.message) : undefined}
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={styles.actionsContainer(responsive)}>
          <Button
            customStyle={styles.clearButton}
            text={t('COMMON:CLEAR_FILTER')}
            color='primary'
            variant='link'
            onClick={reset}
          />
          <IconButton
            aria-label={t('CONSUMPTION:DOWNLOAD')}
            tooltip='CONSUMPTION:DOWNLOAD'
            onClick={() => download(selectedCompany, params)}
            IconComponent={IconFileExport}
            isLoading={isLoadingConsumption}
            disabled={!dataLength}
          />
          <Button
            customStyle={styles.searchButton}
            startIcon={
              <SvgIcon>
                <IconSearch />
              </SvgIcon>
            }
            variant='contained'
            color='primary'
            type='button'
            disabled={disableButton}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchBoxConsumptionByCommerce;
