import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { palette } from 'theme/palette';
import { Box, CircularProgress, Typography } from '@mui/material';
import { IconBuildingStore, IconEye, IconEyeClosed } from '@tabler/icons-react';
import { Snackbar } from '@atoms';
import { OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import { useCompanyDetails, useRoles, useSnackbar, useURLparams } from '@hooks';
import { FullWidthContent, BoxContainer, MainMenuLayout } from '@layouts';
import { CardHeader, GoBackButton } from '@molecules';
import { ClientMovementsTable, SelectCompany } from '@organisms';
import { useCompanyStore } from '@stores';
import { parseAmount } from '@utils';
import usePostalAddresByCompanyId from './data/PlacesRepository/address/hooks/usePostalAddressByCompanyId';
import AddCompanyPostalAddress from './modules/AddCompanyPostalAddress/AddCompanyPostalAddress';
import CompanyDetails from './modules/CompanyDetails/CompanyDetails';
import styles from './styles';

/**
 * Clients (partners) screen for internal users
 */
const ClientsScreen = () => {
  const { t } = useTranslation();
  const { validateRoles } = useRoles();
  const isOperationRole = validateRoles([OPERATIONS, OPERATIONS_SUPERVISOR]);
  const { showSnackbar, snackbarProps } = useSnackbar();

  const navigate = useNavigate();
  const { params, setURLparams } = useURLparams();
  const { selectedCompany, setSelectedCompany } = useCompanyStore();
  const { data, isLoading } = useCompanyDetails(params.company);

  const {
    data: companyPostalAddress,
    isLoading: isLoadingCompanyPostalAddress,
    mutate,
  } = usePostalAddresByCompanyId('company' in params ? params.company : selectedCompany);

  const [showBalance, setShowBalance] = useState(false);

  const clientMenu = [
    {
      text: t('EMPLOYEES:DETAIL:VIEW_DETAIL'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        setURLparams({ ...params, tab: 'details' });
      },
    },
    {
      text: t('COMMON:SEE_REPORTS'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        navigate('/reportes-operaciones', { state: { goBack: -1 } });
      },
    },
    {
      text: t('CLIENTS:DETAIL:ADD_POSTAL_ADDRESS:TITLE'),
      canViewOption: params.tab === 'details',
      onClick: () => {
        setURLparams({ ...params, tab: 'add_postal_address' });
      },
    },
  ];

  /** */
  const onChangeBalance = () => {
    setShowBalance(!showBalance);
  };

  const balance = data?.balance ? parseAmount(data.balance) : '-';
  const leftComponentOptions = {
    type: 'avatar',
    icon: <IconBuildingStore color={palette.primary.main} />,
  };
  const rightComponentOptions = {
    type: 'text',
    text: !showBalance ? balance : '$ *****',
    icon: !showBalance ? <IconEye /> : <IconEyeClosed />,
    onClickIcon: onChangeBalance,
    menuOptions: clientMenu,
  };
  const subtitleOptions = [
    {
      text: data?.cuil,
    },
    ...(data?.customerType
      ? [
          {
            text: t(`CLIENTS:DETAIL:${data.customerType}`),
            variant: 'h4',
          },
        ]
      : []),
  ];

  /**
   * Changes the company
   * @param {object} e
   * @param {object} newValue
   */
  function handelChangeCompany(e, newValue) {
    setURLparams({ ...params, company: newValue.id });
    setSelectedCompany(newValue.id);
  }

  /** Go to previous screen */
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /** Render content depending of "tab" selected */
  const renderContent = (tabSelected) => {
    switch (tabSelected) {
      case 'details': {
        return (
          <>
            <CompanyDetails
              customerId={data.customerId}
              companyPostalAddress={companyPostalAddress}
              isLoading={isLoadingCompanyPostalAddress}
              isOperationRole={isOperationRole}
            />
            <GoBackButton variant='outlined' />
          </>
        );
      }
      case 'add_postal_address': {
        return (
          <>
            <AddCompanyPostalAddress
              companyId={selectedCompany || params.company}
              navigateBack={goBack}
              navigateToDetails={(result) => {
                setURLparams({ ...params, tab: 'details', result }, { replace: true });
              }}
            />
          </>
        );
      }
      default: {
        return (
          <>
            <ClientMovementsTable client={data} />
            <GoBackButton backFunction={() => setURLparams({})} variant='outlined' />
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (params.result && params.result === 'success') {
      // Clean result params from URL for that user can navigate back to the client screen
      goBack();

      mutate();
      showSnackbar(t('CLIENTS:DETAIL:ADD_POSTAL_ADDRESS:SUCCESS'), 'success');
    }
  }, [goBack, mutate, params.result, setURLparams, showSnackbar, t]);

  return (
    <>
      <MainMenuLayout>
        <FullWidthContent>
          {params.company ? (
            isLoading ? (
              <Box sx={styles.spinner}>
                <CircularProgress />
              </Box>
            ) : (
              data && (
                <>
                  <CardHeader
                    withMenu={
                      isOperationRole &&
                      params.tab !== 'add_postal_address' &&
                      (params.tab !== 'details' || !companyPostalAddress)
                    }
                    title={data?.description}
                    leftComponentOptions={leftComponentOptions}
                    rightComponentOptions={rightComponentOptions}
                    subtitleOptions={subtitleOptions}
                  />
                  {renderContent(params.tab)}
                </>
              )
            )
          ) : (
            <>
              <BoxContainer>
                <Typography variant='h4'>{t('COMMON:COMPANY')}</Typography>
                <SelectCompany onChange={handelChangeCompany} value={params.company || ''} />
              </BoxContainer>
            </>
          )}
        </FullWidthContent>
      </MainMenuLayout>
      <Snackbar {...snackbarProps} />
    </>
  );
};

export default ClientsScreen;
