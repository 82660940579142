import { apiClient } from 'config/api';

/**
 * Service to create the user in Auth0
 * @async
 * @param body - body payload request.
 * @param body.userId - user id to create.
 */
async function patchCreateUser({ userId }: { userId: string }) {
  const url = `/auth0/user/${userId}/confirm`;

  const response = await apiClient.patch(url);
  return response.data;
}
export default patchCreateUser;
