import useSWR from 'swr';

/**
 * Obtains a list of areas by customer
 * @param allowRequest - Indicates whether the request to fetch areas should be made. If `false`, the request will not be executed
 * @param customerId - customerId to get customer areas
 */
const useAreasByCustomer = ({
  customerId,
  allowRequest = true,
}: {
  customerId?: number;
  allowRequest?: boolean;
}) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && customerId ? `/v2/partner/areas/${customerId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data: data ?? [], isLoading, error };
};
export default useAreasByCustomer;
