import { CustomStyles } from '@types';

const styles: { container: CustomStyles; nameText: CustomStyles; balanceText: CustomStyles } = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '40%',
    minWidth: '300px',
  },
  nameText: {
    fontWeight: '400',
  },
  balanceText: {
    fontWeight: '600',
  },
};

export default styles;
