const styles = {
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '190px',
  },
  emailMaxWidth: {
    maxWidth: '200px',
  },
  actionButton: {
    minWidth: 0,
    padding: '5px',
  },
  actionButtonBox: {
    padding: 0,
  },
};

export default styles;
