import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import AreaItem from './AreaItem';

export interface Area {
  id: string;
  name: string;
  fantasyName: string;
  balance: number;
}

interface AreasListProps {
  areas: Area[];
}

/**
 * Component that renders a list of available areas for a given company.
 *
 * @param areas The list of areas to be displayed.
 * @returns The rendered component.
 */
const AreasList: FC<AreasListProps> = ({ areas }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1.5}>
      <Typography variant='h2'>{t('CLIENTS:DETAIL:AREAS:TITLE')}</Typography>
      <Stack direction='column' alignItems='flex-start' gap={2}>
        {areas.map((area) => (
          <AreaItem key={area.id} area={area} />
        ))}
      </Stack>
    </Stack>
  );
};

export default AreasList;
