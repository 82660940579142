import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { IconCreditCard } from '@tabler/icons-react';
import { PRODUCTS_COLORS, ProductsAvailablesColors } from '@globalConstants';
import styles from './ProductItem.styles';

/**
 * Component that renders a single customer product with a corresponding icon and color.
 *
 * @param product - The name of the product.
 * @returns The rendered component.
 */
const ProductItem: FC<{ product: ProductsAvailablesColors }> = ({ product }) => (
  <Box sx={styles.container}>
    <IconCreditCard
      stroke={2}
      color={product.colors ? product.colors.main : PRODUCTS_COLORS[product.name]?.main}
    />
    <Typography sx={styles.text}>{product.name}</Typography>
  </Box>
);

export default ProductItem;
