import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Box, Button } from '@mui/material';
import { Alert, Snackbar, StrongModalText } from '@atoms';
import { OPERATIONS } from '@globalConstants';
import { useRoles, useURLparams } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { DEFAULT_ROWS_PER_PAGE, userListColumns } from './constants';
import useApproveUser from './data/useApproveUser/hooks/useApproveUser';
import useGetAllUsersPaginated from './data/useGetAllUsersPaginated';

interface UsersManagmentParams {
  page: string;
  limit: string;
}

/**
 * User management screen
 * @returns The rendered component
 */
const UsersManagmentScreen: FC = () => {
  const { validateRoles } = useRoles();
  const { params, setURLparams } = useURLparams<UsersManagmentParams>({
    page: '1',
    limit: String(DEFAULT_ROWS_PER_PAGE),
  });
  const navigate = useNavigate();

  const {
    data: response,
    isLoading,
    handlePageChange,
    handleRowsPerPageChange,
    handleRefresh,
  } = useGetAllUsersPaginated({
    params,
    setURLparams,
  });
  const {
    handleSubmit,
    onActionClick,
    isModalOpen,
    selectedUser,
    setIsModalOpen,
    isError,
    setIsError,
    setIsSuccess,
    isSuccess,
    alertMessage,
    isLoading: isLoadingAction,
  } = useApproveUser(() => handleRefresh());
  const columns = useMemo(
    () => userListColumns.filter((column) => !column.role || validateRoles(column.role)),
    [validateRoles]
  );
  const items = useMemo(() => {
    return (
      response?.data?.map((item) => ({
        user: item,
        callback: onActionClick,
      })) || []
    );
  }, [response?.data, onActionClick]);
  return (
    <MainMenuLayout>
      <FullWidthContent>
        {validateRoles([OPERATIONS]) && (
          <Box justifyContent={'flex-end'} display={'flex'}>
            <Button variant='contained' onClick={() => navigate('/agregar-usuario')}>
              {t('MENU:USERS_MANAGEMENT:CREATE')}
            </Button>
          </Box>
        )}
        <Table
          columns={columns}
          count={response?.pagination?.totalCount || 0}
          isLoading={isLoading}
          items={items}
          page={params?.page || 1}
          rowsPerPage={params?.limit || DEFAULT_ROWS_PER_PAGE}
          //TODO: Cast the typing to avoid build errors.
          onPageChange={handlePageChange as () => void}
          onRowsPerPageChange={handleRowsPerPageChange as () => void}
        />
        <Alert
          variant='warning'
          modalVisible={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          handleSubmit={handleSubmit}
          closeButtonText='COMMON:CANCEL'
          title={t('PROSPECTS:SEND_EMAIL:MODAL:TITLE')}
          submitLoading={isLoadingAction}
          content={
            <Trans
              i18nKey={`USERS_MANAGEMENT:CONFIRMATION_MODAL:${selectedUser.action}_USER`}
              components={{ s: <StrongModalText /> }}
              values={selectedUser}
            />
          }
        />
        <Snackbar
          open={isError}
          message={t(alertMessage?.description)}
          severity={'error'}
          handleClose={() => setIsError(false)}
        />
        <Snackbar
          open={isSuccess}
          message={t(alertMessage?.description)}
          severity='success'
          handleClose={() => setIsSuccess(false)}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default UsersManagmentScreen;
